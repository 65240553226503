@import url(https://fonts.googleapis.com/css?family=Roboto:400,400italic,500,500italic,700,700italic,900,900italic,300italic,300,100italic,100);


body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(270deg, #338CF5 0%, #D0E6FF 51.01%, #338CF5 100%);
}

code {
  font-family: 'Roboto', sans-serif;
}

.css-ivctx {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.css-ir5vs2 {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  border-bottom: 1px solid;
  border-bottom-color: var(--theme-ui-colors-gray-2,#A0A4AC);
}
.css-b8t00h {
  box-sizing: border-box;
  margin: 0;
  min-width: 0;
  margin-left: 12px;
  margin-right: 12px;
  font-size: 12px;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: var(--theme-ui-colors-gray-2,#A0A4AC);
}